import React, { useState } from 'react';
import './Footer.css';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from React Bootstrap

const Footer = () => {
  const [showTermsModal, setShowTermsModal] = useState(false); // State to control Terms modal visibility
  const [showPrivacyModal, setShowPrivacyModal] = useState(false); // State to control Privacy modal visibility

  const handleCloseTerms = () => setShowTermsModal(false);
  const handleShowTerms = () => setShowTermsModal(true);

  const handleClosePrivacy = () => setShowPrivacyModal(false);
  const handleShowPrivacy = () => setShowPrivacyModal(true);

  return (
    <section className="footer">
      <div className="footer-row">
        <div className="footer-col">
          <h4>Company Name & Address</h4>
          <ul className="links">
            <li><a href="#">Kaushalyatech Solutions Pvt Ltd</a></li>
            <li><a href="#">OFFICE NO 104, <br />ARENA LOTUS ABOVE SBI BANK, <br />VADAGAO SHERI,<br/>PUNE - 411014</a></li>
          </ul>
        </div>

        <div className="footer-col">
          <h4>Explore</h4>
          <ul className="links">
            <li><a href="#">Logo Designing</a></li>
            <li><a href="#">Website Development</a></li>
            <li><a href="#">Digital Marketing</a></li>
          </ul>
        </div>

        <div className="footer-col">
          <h4>Legal</h4>
          <ul className="links">
            <li><a href="#" onClick={handleShowTerms}>Terms and Conditions</a></li>
            <li><a href="#" onClick={handleShowPrivacy}>Privacy Policy</a></li>
            {/* <li><a href="#">GDPR</a></li>
            <li><a href="#">Security</a></li>
            <li><a href="#">Testimonials</a></li>
            <li><a href="#">Media Kit</a></li> */}
          </ul>
        </div>

        <div className="footer-col">
          <h4>Location</h4>
          <p>Our Premises are Located in Wadgaon Sheri Pune.</p>
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15130.131080078041!2d73.92039299999999!3d18.5500045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c16f0414015b%3A0x39a3cd31679bff9d!2sWadgaon%20Sheri%2C%20Pune%2C%20Maharashtra%20411014!5e0!3m2!1sen!2sin!4v1717501172378!5m2!1sen!2sin"
            title="location"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <div className="icons">
            <a href="https://www.facebook.com/"><i className="fa-brands fa-facebook-f"></i></a>
            <a href="https://twitter.com/"><i className="fa-brands fa-twitter"></i></a>
            <a href="https://www.linkedin.com/"><i className="fa-brands fa-linkedin"></i></a>
            <a href="https://github.com/"><i className="fa-brands fa-github"></i></a>
          </div>
        </div>
      </div>

      <p><center><i className="fa fa-copyright" aria-hidden="true"></i> 2024 All rights reserved by Kaushalyatech Solutions</center></p>

      {/* Modal for Terms and Conditions */}
      <Modal show={showTermsModal} onHide={handleCloseTerms}>
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Effective Date:</strong> February 9, 2024</p>
          <p>Welcome to Kaushalyatech Solutions. These Terms and Conditions govern your access to our services. By using our services, you agree to these terms.</p>
          <h5>1. Services</h5>
          <p>Kaushalyatech Solutions provides a variety of services, including but not limited to Logo Designing, Website Development, and Digital Marketing.</p>
          <h5>2. Account Creation</h5>
          <p>You must be at least 18 years of age to create an account. You are responsible for keeping your account secure.</p>
          <h5>3. Intellectual Property</h5>
          <p>All content, logos, and designs provided by Kaushalyatech Solutions are the intellectual property of Kaushalyatech Solutions unless otherwise stated.</p>
          <h5>4. Payment and Fees</h5>
          <p>All fees are outlined in separate agreements and must be paid accordingly. Failure to pay may result in service suspension.</p>
          <h5>5. Termination</h5>
          <p>We reserve the right to terminate services if these terms are violated.</p>
          <p>For more detailed information, please contact us.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseTerms}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Privacy Policy */}
      <Modal show={showPrivacyModal} onHide={handleClosePrivacy}>
        <Modal.Header closeButton>
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Effective Date:</strong> February 9, 2024</p>
          <p>Kaushalyatech Solutions Pvt Ltd ("Kaushalyatech Solutions Pvt Ltd," "we," "us," "our") takes your privacy very seriously. This Privacy Policy outlines the types of information we collect, how it's used, and the choices you have regarding your personal data.</p>
          <h5>1. Information We Collect</h5>
          <p>We collect personal information such as contact information, website usage data, and third-party data to better understand your needs and provide services.</p>
          <h5>2. How We Use Your Information</h5>
          <p>Your information is used for communication, website improvement, personalized content, and business analytics.</p>
          <h5>3. Sharing Your Information</h5>
          <p>We share your data with service providers, during business transfers, or for legal compliance. </p>
          <h5>4. Your Choices</h5>
          <p>You can opt out of marketing, control cookies, and manage your privacy settings via browser features.</p>
          <p>For more detailed information, please contact us at: support@kaushalyatechsolutions.in</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePrivacy}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default Footer;
